/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { addMonths, endOfMonth } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Missions, RowType } from "./Missions";
import { useGetAllMissions } from "./hooks/useGetAllMissions";
import { useGetAllRewardsVouchers } from "./hooks/useGetAllRewardsVouchers";
import { TabData, TabWrapper } from "components/Tabs/TabWrapper";
import { SolidPlus } from "styles/icons/solid";

export const Rewards = () => {
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(window.location.hash.replace("#", "") || "0"),
  );

  const { t } = useTranslation();

  const { data: missions, isLoading: missionsIsLoading } = useGetAllMissions();

  const { data: vouchers, isLoading: vouchersIsLoading } =
    useGetAllRewardsVouchers();

  const rows = [];

  if (vouchers && missions) {
    for (const voucher of vouchers) {
      for (const mission of missions) {
        const theMission =
          mission[`en-${voucher.retailer.countryCode}`.toLowerCase()];
        const targetReached = voucher.results.includes(theMission.name);
        const theProgress = voucher.progresses.find(
          (f) => f.mission === theMission.name,
        );
        const progressPercent = Math.round(
          (theProgress.progress / theProgress.target) * 100,
        );

        // We assume the voucher expiry is the end of the month after the mission end date
        // The actual expiry date is stored in the database
        const voucherExpiry = endOfMonth(
          addMonths(new Date(theMission.end_date as string), 1),
        )
          .toISOString()
          .split("T")[0];

        rows.push({
          mission: theMission.name,
          countryCode: voucher.retailer.countryCode,
          desc: theMission.description,
          firstName: voucher.retailer.firstName,
          lastName: voucher.retailer.lastName,
          email: voucher.retailer.email,
          voucherCode: targetReached ? theMission.voucher_code : "",
          targetReached: targetReached,
          progressPercent: progressPercent > 100 ? 100 : progressPercent,
          retailerId: voucher.retailer.retailerId,
          phoneNumber: voucher.retailer.phoneNumber ?? "coming soon",
          progress: theProgress.progress,
          localCurrency: theMission.unit,
          voucherExpiry: voucherExpiry,
        } as RowType);
      }
    }
  }

  const tabsData: TabData[] = [
    {
      title: t("rewards.missions"),
      content: (
        <Missions
          missions={rows}
          isLoading={missionsIsLoading || vouchersIsLoading}
        />
      ),
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    window.location.hash = `${index}`;
  };

  return (
    <Box margin="auto">
      <Flex display="column">
        <Flex justifyContent="space-between">
          <Heading as="h1" size="h1" fontWeight="bold" mb={2} mt={5} ml={10}>
            {t("Rewards")}
          </Heading>
          <Button
            size="lg"
            mt={15}
            mr={8}
            leftIcon={<SolidPlus fontSize={24} />}
            fontWeight="semibold"
            onClick={() =>
              window.open(
                "https://strapi.markato.com/admin/content-manager/collection-types/api::reward.reward",
                "_blank",
              )
            }
            data-test-id="createVoucher"
          >
            {t("rewards.create_mission")}
          </Button>
        </Flex>
        <TabWrapper
          tabListProps={{ pl: 10 }}
          tabPanelsProps={{ pl: 2 }}
          tabsData={tabsData}
          tabIndex={tabIndex}
          handleTabsChange={handleTabsChange}
        />
      </Flex>
    </Box>
  );
};
